@import url("https://fonts.googleapis.com/css?family=Exo%202:700,900");

html,
body {
  margin: 0;
  font-family: "Exo 2", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
p,
i,
a,
.first-letter,
.authorName a {
  text-rendering: optimizeLegibility;
}

h1 {
  font-family: "Exo 2", serif;
  font-size: 48px;
  text-align: left;
  margin-bottom: 8px;
}

h2 {
  font-family: "Exo 2", sans-serif;
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  margin: 40px 0 40px 0px;
  text-align: left;
  line-height: 34.5px;
  letter-spacing: -0.45px;
}

p,
i,
a,
li {
  margin-top: 21px;
}

p,
i,
a,
li,
span {
  font-family: "Exo 2";
  font-size: 21px;
  letter-spacing: -0.03px;
  line-height: 1.58;
}

a {
  text-decoration: underline;
}

blockquote {
  font-family: "Playfair Display", serif;
  font-size: 25px;
  font-style: italic;
  letter-spacing: -0.36px;
  line-height: 44.4px;
  overflow-wrap: break-word;
  margin: 30px 0 33px 0;
  padding: 0 0 0 1em;
  border-left: .25em solid #dfe2e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  font-size: 18px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 3px 5px;
}

pre > code {
  background: transparent !important;
  font-family: "Quicksand";
  font-size: 18px;
  padding: 0px;
}

pre {
  margin-top: 30px;
  border-radius: 10px;
  padding: 20px !important;
}

mark,
.highlighted {
  background: #7dffb3;
}

.first-letter {
  overflow-wrap: break-word;
  font-family: "Exo 2", serif;
  font-size: 60px;
  line-height: 60px;
  display: block;
  position: relative;
  float: left;
  margin: 0px 7px 0 -5px;
}

.subtitle {
  font-family: "Exo 2", sans-serif;
  color: rgba(0, 0, 0, 0.54);
  margin: 0 0 24px 0;
}

::selection {
  background-color: lavender;
}

/* Slide up */

.blog-post-anchor {
  color: inherit;
  text-decoration: none;
  box-shadow: inset 0 -0.125em 0 #434C5E;
  transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
  padding-right: 2px;
  padding-left: 2px;
}

.blog-post-anchor:hover {
  box-shadow: inset 0 -1.125em 0 #434C5E;
  color: white;
}

img {
  max-width: 100%;
  height: auto;
}

@keyframes scale-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media only screen and (max-width: 768px) {
  p,
  i,
  a,
  li {
    font-size: 18px;
  }
  blockquote {
    font-size: 20px;
  }
}
